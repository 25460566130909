export default {
    "en": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "User created",
                    "updated": "User updated",
                    "deleted": "User deleted",
                    "bulk_destroyed": "Selected users deleted",
                    "bulk_enabled": "Selected users enabled",
                    "bulk_disabled": "Selected users disabled"
                },
                "roles": {
                    "created": "Role created",
                    "updated": "Role updated",
                    "deleted": "Role deleted"
                },
                "metas": {
                    "created": "Meta created",
                    "updated": "Meta updated",
                    "deleted": "Meta deleted",
                    "bulk_destroyed": "Selected metas deleted"
                },
                "form_submissions": {
                    "deleted": "Submission deleted",
                    "bulk_destroyed": "Selected submissions deleted"
                },
                "form_settings": {
                    "created": "Form setting created",
                    "updated": "Form setting updated",
                    "deleted": "Form setting deleted"
                },
                "redirections": {
                    "created": "Redirection created",
                    "updated": "Redirection updated",
                    "deleted": "Redirection deleted",
                    "bulk_destroyed": "Selected redirections deleted",
                    "bulk_enabled": "Selected redirections enabled",
                    "bulk_disabled": "Selected redirections disabled",
                    "file_imported": "File successfully imported"
                },
                "posts": {
                    "created": "Post created",
                    "updated": "Post updated",
                    "deleted": "Post deleted",
                    "bulk_destroyed": "Selected posts deleted",
                    "bulk_published": "Selected posts published",
                    "bulk_pending": "Selected posts are awaiting moderation",
                    "bulk_pinned": "Selected posts pinned",
                    "bulk_promoted": "Selected posts promoted"
                },
                "movies": {
                    "created": "Movie created",
                    "updated": "Movie updated",
                    "deleted": "Movie deleted",
                    "bulk_destroyed": "Selected movies deleted",
                    "bulk_published": "Selected movies published",
                    "bulk_pending": "Selected movies are awaiting moderation",
                    "bulk_pinned": "Selected movies pinned",
                    "bulk_promoted": "Selected movies promoted"
                },
                "shows": {
                    "created": "Show created",
                    "updated": "Show updated",
                    "deleted": "Show deleted",
                    "bulk_destroyed": "Selected shows deleted",
                    "bulk_published": "Selected shows published",
                    "bulk_pending": "Selected shows are awaiting moderation",
                    "bulk_pinned": "Selected shows pinned",
                    "bulk_promoted": "Selected shows promoted"
                },
                "seasons": {
                    "created": "Season created",
                    "updated": "Season updated",
                    "deleted": "Season deleted"
                },
                "people": {
                    "created": "People created",
                    "updated": "People updated",
                    "deleted": "People deleted",
                    "bulk_destroyed": "Selected people deleted",
                    "bulk_published": "Selected people published",
                    "bulk_pending": "Selected people are awaiting moderation",
                    "bulk_pinned": "Selected people pinned",
                    "bulk_promoted": "Selected people promoted"
                },
                "actions": {
                    "invalid": "Invalid action"
                }
            },
            "frontend": []
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply",
            "add": "Add",
            "remove": "Remove",
            "change": "Change",
            "users": {
                "create": "Create user"
            },
            "roles": {
                "create": "Create role"
            },
            "metas": {
                "create": "Create meta"
            },
            "form_settings": {
                "create": "Create setting"
            },
            "redirections": {
                "create": "Create redirection",
                "import": "Import CSV"
            },
            "posts": {
                "create": "Create post",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            },
            "movies": {
                "create": "Create movies",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            },
            "videos": {
                "create": "Create videos",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            },
            "shows": {
                "create": "Create shows",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft",
                "relations": {
                    "seasons": {
                        "create": "Create new season"
                    },
                    "videos": {
                        "add": "Add videos",
                        "create": "Create videos"
                    },
                    "people": {
                        "add": "Add casting member",
                        "create": "Create casting member"
                    },
                    "stars": {
                        "add": "Add starring people",
                        "create": "Create starring people"
                    }
                }
            },
            "seasons": {
                "create": "Create shows",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft",
                "relations": {
                    "videos": {
                        "add": "Add videos"
                    }
                }
            },
            "people": {
                "create": "Create people",
                "relations": {
                    "people": {
                        "add": "Add casting member",
                        "create": "Create casting member"
                    },
                    "stars": {
                        "add": "Add starring people",
                        "create": "Create starring people"
                    }
                }
            }
        },
        "exceptions": {
            "general": "Server exception",
            "unauthorized": "Action not allowed",
            "disconnected": "Session expired, please login",
            "backend": {
                "users": {
                    "create": "Error on user creation",
                    "update": "Error on user updating",
                    "delete": "Error on user deletion",
                    "first_user_cannot_be_edited": "You cannot edit super admin user",
                    "first_user_cannot_be_disabled": "Super admin user cannot be disabled",
                    "first_user_cannot_be_destroyed": "Super admin user cannot be deleted",
                    "first_user_cannot_be_impersonated": "Super admin user cannot be impersonated",
                    "cannot_set_superior_roles": "You cannot attribute roles superior to yours"
                },
                "roles": {
                    "create": "Error on role creation",
                    "update": "Error on role updating",
                    "delete": "Error on role deletion"
                },
                "metas": {
                    "create": "Error on meta creation",
                    "update": "Error on meta updating",
                    "delete": "Error on meta deletion",
                    "already_exist": "There is already a meta for this locale route"
                },
                "form_submissions": {
                    "create": "Error on submission creation",
                    "delete": "Error on submission deletion"
                },
                "form_settings": {
                    "create": "Error on form setting creation",
                    "update": "Error on form setting updating",
                    "delete": "Error on form setting deletion",
                    "already_exist": "There is already a setting linked to this form"
                },
                "redirections": {
                    "create": "Error on redirection creation",
                    "update": "Error on redirection updating",
                    "delete": "Error on redirection deletion",
                    "already_exist": "There is already a redirection for this path"
                },
                "posts": {
                    "create": "Error on post creation",
                    "update": "Error on post updating",
                    "save": "Error on post saving",
                    "delete": "Error on post deletion"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "That e-mail address is already taken.",
                    "password_mismatch": "That is not your old password.",
                    "delete_account": "Error on account deletion.",
                    "updating_disabled": "Account editing is disabled."
                },
                "auth": {
                    "registration_disabled": "Registration is disabled."
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "Contact form"
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "search_results": "Search results",
            "search_no_results": "No results for your search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "browse": "Browse",
            "download": "Download",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "remove": "Remove",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "order": "Reorder",
            "share": "Share",
            "loading": "Loading",
            "password": "Password",
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "modal": {
                "relation": {
                    "people": {
                        "name": "Add People"
                    },
                    "stars": {
                        "name": "Add People"
                    },
                    "videos": {
                        "title": "Add Videos"
                    }
                }
            },
            "morphs": {
                "post": "Post, identity {id}",
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "fr": "French"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag",
                "genres": "Select or create a genres",
                "positions": "Select or create a position"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "password_wrong": "Wrong password",
                "register": "Register",
                "subscribe": "Subscribe",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrateur",
                "connect_with": "Login with Facebook :",
                "account_delete": "This action will delete entirely your account from this site as well as all associated data.",
                "account_deleted": "Account successfully deleted",
                "membership_and_billing": "Membership & Billing",
                "membership_account": "Account",
                "membership_invoices": "Membership Invoices",
                "membership_payperview_invoices": "Pay-Per-View Invoices",
                "cancel_membership": "Cancel membership",
                "membership_ends_at": "Your membership will ends on",
                "membership_resume": "Resume membership",
                "card_update": "Update",
                "next_payment": "Next payment",
                "my_card": "My card",
                "change_plan": "Change Plan",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                },
                "sidebar": {
                    "vod": "VOD management",
                    "content": "Content management",
                    "forms": "Form management",
                    "access": "Access management",
                    "seo": "SEO settings",
                    "logs": "Logs"
                },
                "titles": {
                    "dashboard": "Dashboard"
                },
                "users": {
                    "titles": {
                        "main": "User",
                        "index": "User list",
                        "create": "User creation",
                        "edit": "User modification"
                    },
                    "actions": {
                        "destroy": "Delete selected users",
                        "enable": "Enable selected users",
                        "disable": "Disable selected users"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Role",
                        "index": "Role list",
                        "create": "Role creation",
                        "edit": "Role modification"
                    }
                },
                "logs": {
                    "titles": {
                        "main": "Logs",
                        "index": "List logs",
                        "show": "View log",
                        "edit": "Edit log"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "Meta",
                        "index": "Meta list",
                        "create": "Meta creation",
                        "edit": "Meta modification"
                    },
                    "actions": {
                        "destroy": "Delete selected metas"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Submission",
                        "index": "Submission list",
                        "show": "Submission detail"
                    },
                    "actions": {
                        "destroy": "Delete selected submissions"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "Forms",
                        "index": "Form setting list",
                        "create": "Form setting creation",
                        "edit": "Form setting modification"
                    },
                    "descriptions": {
                        "recipients": "Example: 'webmaster@example.com' or 'sales@example.com,support@example.com' . To specify multiple recipients, separate each email address with a comma.",
                        "message": "The message to display to the user after submission of this form. Leave blank for no message."
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "Redirection",
                        "index": "Redirection list",
                        "create": "Redirection creation",
                        "edit": "Redirection modification"
                    },
                    "actions": {
                        "destroy": "Delete selected redirections",
                        "enable": "Enable selected redirections",
                        "disable": "Disable selected redirections"
                    },
                    "types": {
                        "permanent": "Permanent redirect (301)",
                        "temporary": "Temporary redirect (302)"
                    },
                    "import": {
                        "title": "CSV file import",
                        "label": "Select CSV file to import",
                        "description": "File must have 2 columns with \"source\" and \"target\" as heading, redirection will be permanent by default"
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "Draft",
                        "pending": "Pending",
                        "published": "Published"
                    },
                    "titles": {
                        "main": "Posts",
                        "index": "Post list",
                        "create": "Create post",
                        "edit": "Edit post",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Delete selected posts",
                        "publish": "Publish selected posts",
                        "pin": "Pin selected posts",
                        "promote": "Promote selected posts"
                    }
                },
                "movies": {
                    "statuses": {
                        "draft": "Draft",
                        "pending": "Pending",
                        "published": "Published"
                    },
                    "titles": {
                        "main": "Movies",
                        "index": "Movie list",
                        "create": "Create movie",
                        "edit": "Edit movie",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Delete selected movies",
                        "publish": "Publish selected movies",
                        "pin": "Pin selected movies",
                        "promote": "Promote selected movies"
                    },
                    "relations": {
                        "movie_people": "Distribution",
                        "movie_stars": "Starring"
                    }
                },
                "shows": {
                    "statuses": {
                        "draft": "Draft",
                        "pending": "Pending",
                        "published": "Published"
                    },
                    "titles": {
                        "main": "Shows",
                        "index": "Show list",
                        "create": "Create show",
                        "edit": "Edit show",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Delete selected shows",
                        "publish": "Publish selected shows",
                        "pin": "Pin selected shows",
                        "promote": "Promote selected shows"
                    },
                    "relations": {
                        "show_people": "Distribution",
                        "show_stars": "Starring",
                        "show_seasons": {
                            "create": "Create new season"
                        }
                    }
                },
                "seasons": {
                    "statuses": {
                        "draft": "Draft",
                        "pending": "Pending",
                        "published": "Published"
                    },
                    "titles": {
                        "main": "Seasons",
                        "main_singular": "Season",
                        "create": "Create a season",
                        "edit": "Edit season"
                    }
                },
                "videos": {
                    "titles": {
                        "main": "Videos",
                        "index": "Videos list",
                        "create": "Create video",
                        "edit": "Edit video"
                    },
                    "add_video": "Add video",
                    "actions": {
                        "destroy": "Delete selected video",
                        "remove": "Remove selected video"
                    }
                },
                "video": {
                    "add_video": "Add video"
                },
                "people": {
                    "titles": {
                        "main": "People",
                        "index": "People list",
                        "create": "Create people",
                        "edit": "Edit people",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "actions": {
                        "remove": "Remove selected people",
                        "destroy": "Remove selected people"
                    }
                }
            },
            "frontend": {
                "titles": {
                    "home": "Home",
                    "about": "About",
                    "contact": "Contact",
                    "blog": "Blog",
                    "message_sent": "Message sent",
                    "legal_mentions": "Legal mentions",
                    "terms_of_use": "Terms of use",
                    "administration": "Administration",
                    "discover": "Discover",
                    "watch": "Watch"
                },
                "submissions": {
                    "message_sent": "<p>Your message has been successfully sent</p>"
                },
                "placeholders": {
                    "locale": "Select your language",
                    "timezone": "Select your timezone"
                },
                "blog": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "tags": "Tags"
                },
                "payment": {
                    "checkout": {
                        "plan": {
                            "title": "Choose Your TV Experience",
                            "subtitle": "Switch plans and cancel anytime.",
                            "choose_plan_label": "Choose your plan",
                            "choose_plan_title": "Choose your plan",
                            "checkout": "Checkout",
                            "confirmation": "Confirmation",
                            "limited_access": "Limited access to free content, and trailers",
                            "month": "month",
                            "plan_1": "Essential",
                            "plan_2": "Premium",
                            "plan_3": "Basic"
                        },
                        "email": "Email",
                        "firstname": "Firstname",
                        "name": "Name",
                        "card_number": "Card Number",
                        "card_expiration": "Expiration date",
                        "card_code": "Security code (CVV)",
                        "pay": "Submit payment",
                        "one_month_free": "Your first month is free",
                        "continue_as_guest": "Start free",
                        "plan_1": "Essential Plan",
                        "plan_2": "Premium Plan",
                        "free_plan": "Free",
                        "current_plan": "Current plan",
                        "wait_message": "We are processing, your payment",
                        "success_message": "Thank You ! Your payment has been accepted !",
                        "error_message": "We were unable to process your payment, please try again or contact us.",
                        "start_watching": "Start watching",
                        "back": "Back",
                        "validate": "Validate",
                        "password": "Your password",
                        "review_validate": "Your new plan",
                        "validate_switch": "Switch plan",
                        "switch_charge": "Free in trial period, otherwise charges may occur if you switch to a higher plan."
                    }
                }
            },
            "vodapp": {
                "titles": {
                    "home": "Home",
                    "message_sent": "Message sent",
                    "administration": "Administration",
                    "season": "Season",
                    "episode": "Episode"
                },
                "submissions": {
                    "message_sent": "<p>Your message has been successfully sent</p>"
                },
                "placeholders": {
                    "locale": "Select your language",
                    "timezone": "Select your timezone"
                },
                "home": {
                    "titles": {
                        "trailers": "Comming soon",
                        "movies": "Movies",
                        "shows": "Shows",
                        "docs": "Documentary",
                        "staytune": "Découvrez Montagne TV"
                    },
                    "texts": {
                        "staytune": "La nouvelle aventure montagneTV arrive trés bientôt, inscrivez-vous dès maintenant et profitez en avant premières des nouveaux contenus originaux Montagne TV et d'offres spéciales"
                    }
                },
                "videos": {
                    "watch_trailer": "Watch trailer",
                    "watch": "Watch",
                    "play": "Play",
                    "continue": "Continue",
                    "my_list": "My list",
                    "my_list_adding": "Adding",
                    "my_list_removing": "Removing",
                    "my_list_added": "Video added to my list",
                    "my_list_removed": "Video removed from my list",
                    "countdown": {
                        "playingin": "Start in",
                        "seconds": "s"
                    }
                },
                "player": {
                    "ad": {
                        "adTitle": "Ad",
                        "skipLabel": "Skip ad"
                    }
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "User ID {user} created",
                    "updated": "User ID {user} updated",
                    "deleted": "User ID {user} deleted"
                },
                "form_submissions": {
                    "created": "Form submission ID {form_submission} created"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Hello !",
                "regards": "Regards",
                "trouble": "If you’re having trouble clicking the {action} button, copy and paste the URL below into your web browser :",
                "all_rights_reserved": "All rights reserved."
            },
            "password_reset": {
                "subject": "Password reset",
                "intro": "You are receiving this email because we received a password reset request for your account.",
                "action": "Reset Password",
                "outro": "If you did not request a password reset, no further action is required."
            },
            "contact": {
                "subject": "New contact message",
                "new_contact": "You've got a new contact message. Submission detail :"
            },
            "alert": {
                "subject": "[{app_name}] Exception error",
                "message": "You've got unexpected server exception error which message is : {message}.",
                "trace": "All trace detail :"
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "permissions": {
            "categories": {
                "blog": "Blog",
                "vod": "VOD",
                "form": "Forms",
                "access": "Access",
                "seo": "SEO"
            },
            "access": {
                "backend": {
                    "display_name": "Backoffice access",
                    "description": "Can access to administration pages."
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "View form settings",
                    "description": "Can view form settings."
                },
                "form_submissions": {
                    "display_name": "View form submissions",
                    "description": "Can view form submissions."
                },
                "users": {
                    "display_name": "View users",
                    "description": "Can view users."
                },
                "roles": {
                    "display_name": "View roles",
                    "description": "Can view roles."
                },
                "logs": {
                    "display_name": "View logs",
                    "description": "Can view logs."
                },
                "metas": {
                    "display_name": "View metas",
                    "description": "Can view metas."
                },
                "redirections": {
                    "display_name": "View redirections",
                    "description": "Can view redirections."
                },
                "posts": {
                    "display_name": "View all posts",
                    "description": "Can view all posts."
                },
                "movies": {
                    "display_name": "View all movies",
                    "description": "Can view all movies."
                },
                "shows": {
                    "display_name": "View all shows",
                    "description": "Can view all shows."
                },
                "seasons": {
                    "display_name": "View all seasons",
                    "description": "Can view all seasons."
                },
                "videos": {
                    "display_name": "View all videos",
                    "description": "Can view all videos."
                },
                "people": {
                    "display_name": "View all people",
                    "description": "Can view all people."
                },
                "own": {
                    "posts": {
                        "display_name": "View own posts",
                        "description": "Can view own posts."
                    },
                    "movies": {
                        "display_name": "View own movies",
                        "description": "Can view own movies."
                    },
                    "shows": {
                        "display_name": "View own shows",
                        "description": "Can view own shows."
                    },
                    "seasons": {
                        "display_name": "View own seasons",
                        "description": "Can view own seasons."
                    },
                    "videos": {
                        "display_name": "View own videos",
                        "description": "Can view own videos."
                    },
                    "people": {
                        "display_name": "View own people",
                        "description": "Can view own people."
                    }
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "Create form settings",
                    "description": "Can create form settings."
                },
                "users": {
                    "display_name": "Create users",
                    "description": "Can create users."
                },
                "roles": {
                    "display_name": "Create roles",
                    "description": "Can create roles."
                },
                "metas": {
                    "display_name": "Create metas",
                    "description": "Can create metas."
                },
                "redirections": {
                    "display_name": "Create redirections",
                    "description": "Can create redirections."
                },
                "posts": {
                    "display_name": "Create posts",
                    "description": "Can create all posts."
                },
                "movies": {
                    "display_name": "Create movies",
                    "description": "Can create all movies."
                },
                "shows": {
                    "display_name": "Create shows",
                    "description": "Can create all shows."
                },
                "videos": {
                    "display_name": "Create videos",
                    "description": "Can create all videos."
                },
                "people": {
                    "display_name": "Create people",
                    "description": "Can create all people."
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "Edit form settings",
                    "description": "Can edit form settings."
                },
                "users": {
                    "display_name": "Edit users",
                    "description": "Can edit users."
                },
                "roles": {
                    "display_name": "Edit roles",
                    "description": "Can edit roles."
                },
                "metas": {
                    "display_name": "Edit metas",
                    "description": "Can edit metas."
                },
                "redirections": {
                    "display_name": "Edit redirections",
                    "description": "Can edit redirections."
                },
                "posts": {
                    "display_name": "Edit all posts",
                    "description": "Can edit all posts."
                },
                "movies": {
                    "display_name": "Edit all movies",
                    "description": "Can edit all movies."
                },
                "shows": {
                    "display_name": "Edit all shows",
                    "description": "Can edit all shows."
                },
                "videos": {
                    "display_name": "Edit all videos",
                    "description": "Can edit all videos."
                },
                "people": {
                    "display_name": "Edit all people",
                    "description": "Can edit all people."
                },
                "own": {
                    "posts": {
                        "display_name": "Edit own posts",
                        "description": "Can edit own posts."
                    },
                    "movies": {
                        "display_name": "Edit own movies",
                        "description": "Can edit own movies."
                    },
                    "shows": {
                        "display_name": "Edit own shows",
                        "description": "Can edit own shows."
                    },
                    "videos": {
                        "display_name": "Edit own videos",
                        "description": "Can edit own videos."
                    },
                    "people": {
                        "display_name": "Edit own people",
                        "description": "Can edit own people."
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "Delete form settings",
                    "description": "Can delete form settings."
                },
                "form_submissions": {
                    "display_name": "Delete form submissions",
                    "description": "Can delete form submissions."
                },
                "users": {
                    "display_name": "Delete users",
                    "description": "Can delete users."
                },
                "roles": {
                    "display_name": "Delete roles",
                    "description": "Can delete roles."
                },
                "metas": {
                    "display_name": "Delete metas",
                    "description": "Can delete metas."
                },
                "redirections": {
                    "display_name": "Delete redirections",
                    "description": "Can delete redirections."
                },
                "posts": {
                    "display_name": "Delete all posts",
                    "description": "Can delete all posts."
                },
                "movies": {
                    "display_name": "Delete all movies",
                    "description": "Can delete all movies."
                },
                "shows": {
                    "display_name": "Delete all movies",
                    "description": "Can delete all movies."
                },
                "seasons": {
                    "display_name": "Delete all seasons",
                    "description": "Can delete all seasons."
                },
                "videos": {
                    "display_name": "Delete all videos",
                    "description": "Can delete all videos."
                },
                "people": {
                    "display_name": "Delete all people",
                    "description": "Can delete all people."
                },
                "own": {
                    "posts": {
                        "display_name": "Delete own posts",
                        "description": "Can delete own posts."
                    },
                    "movies": {
                        "display_name": "Delete own movies",
                        "description": "Can delete own movies."
                    },
                    "shows": {
                        "display_name": "Delete own shows",
                        "description": "Can delete own shows."
                    },
                    "videos": {
                        "display_name": "Delete own videos",
                        "description": "Can delete own videos."
                    },
                    "people": {
                        "display_name": "Delete own people",
                        "description": "Can delete own people."
                    }
                }
            },
            "publish": {
                "posts": {
                    "display_name": "Publish posts",
                    "description": "Can manage posts publication."
                },
                "movies": {
                    "display_name": "Publish movies",
                    "description": "Can manage movies publication."
                },
                "shows": {
                    "display_name": "Publish shows",
                    "description": "Can manage shows publication."
                }
            },
            "impersonate": {
                "display_name": "Impersonate user",
                "description": "Can take ownership of others user identities. Useful for tests."
            }
        },
        "routes": {
            "home": "/",
            "about": "about",
            "contact": "contact",
            "contact-sent": "contact-sent",
            "legal-mentions": "legal-mentions",
            "terms-of-use": "terms-of-use",
            "redactors": "blog/redactors/{user}",
            "seo": {
                "home": {
                    "title": "Home",
                    "description": "Welcome to Montagne TV on demand video service"
                },
                "browse": {
                    "movies": {
                        "title": "Browse movies",
                        "description": "Watch your favorites movies on Montagne TV VOD"
                    },
                    "shows": {
                        "title": "Browse shows",
                        "description": "Watch your favorites shows on Montagne TV VOD"
                    }
                },
                "search": "Search"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "free_access": "Free access",
                "body": "Body",
                "tags": "Tags",
                "genres": "Genres",
                "positions": "Position",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "release_date": "Release date",
                "trailer_only": "Trailer only",
                "videos": "Videos",
                "videos_singular": "Video",
                "video_service_id": "Video (service ID)",
                "video_service_id_help": "Please enter the video id, provided by the streaming service",
                "trailer_service_id": "Trailer (service ID)",
                "trailer_service_id_help": "Please enter the video id, provided by the streaming service",
                "small_loop_service_id": "Small trailer service ID",
                "people_title_help": "Administrative title (exple : George Clooney Filmmaker)"
            }
        }
    },
    "fr": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "Utilisateur créé",
                    "updated": "Utilisateur mis à jour",
                    "deleted": "Utilisateur supprimé",
                    "bulk_destroyed": "Utilisateurs sélectionnés supprimés",
                    "bulk_enabled": "Utilisateurs sélectionnés activés",
                    "bulk_disabled": "Utilisateurs sélectionnés désactivés"
                },
                "roles": {
                    "created": "Rôle créé",
                    "updated": "Rôle mis à jour",
                    "deleted": "Rôle supprimé"
                },
                "metas": {
                    "created": "Meta créée",
                    "updated": "Meta mise à jour",
                    "deleted": "Meta supprimée",
                    "bulk_destroyed": "Metas sélectionnées supprimées"
                },
                "form_submissions": {
                    "deleted": "Soumission supprimée",
                    "bulk_destroyed": "Soumissions sélectionnées supprimées"
                },
                "form_settings": {
                    "created": "Paramétrage de formulaire créé",
                    "updated": "Paramétrage de formulaire mis à jour",
                    "deleted": "Paramétrage de formulaire supprimé"
                },
                "redirections": {
                    "created": "Redirection créée",
                    "updated": "Redirection mise à jour",
                    "deleted": "Redirection supprimée",
                    "bulk_destroyed": "Redirections sélectionnées supprimées",
                    "bulk_enabled": "Redirections sélectionnées activées",
                    "bulk_disabled": "Redirections sélectionnées désactivées",
                    "file_imported": "Fichier importé avec succès"
                },
                "posts": {
                    "created": "Article créé",
                    "updated": "Article mis à jour",
                    "deleted": "Article supprimé",
                    "bulk_destroyed": "Articles sélectionnés supprimés",
                    "bulk_published": "Articles sélectionnés publiés",
                    "bulk_pending": "Articles sélectionnés en attente de modération",
                    "bulk_pinned": "Articles sélectionnés épinglé",
                    "bulk_promoted": "Articles sélectionnés mis en avant"
                },
                "campaigns": {
                    "created": "Campagne créée",
                    "updated": "Campagne mise à jour",
                    "deleted": "Campagne supprimée",
                    "bulk_destroyed": "Campagnes sélectionnées supprimées",
                    "bulk_published": "Campagnes sélectionnées publiées",
                    "bulk_pending": "Campagnes sélectionnées en attente de modération"
                },
                "registrations": {
                    "created": "Candidature créée",
                    "updated": "Candidature mise à jour",
                    "deleted": "Candidature supprimée",
                    "bulk_destroyed": "Candidatures sélectionnées supprimées",
                    "bulk_published": "Candidatures sélectionnées publiées",
                    "bulk_pending": "Candidatures sélectionnées en attente de modération",
                    "bulk_invited": "Invitations envoyées",
                    "bulk_notified": "Relances emails envoyées"
                },
                "actions": {
                    "invalid": "Action invalide"
                }
            },
            "frontend": []
        },
        "auth": {
            "failed": "Ces identifiants ne correspondent pas à nos enregistrements",
            "throttle": "Trop de tentatives de connexion. Veuillez essayer de nouveau dans {seconds} secondes.",
            "socialite": {
                "unacceptable": "{provider} n'est pas accepté."
            }
        },
        "buttons": {
            "cancel": "Annuler",
            "save": "Sauvegarder",
            "close": "Fermer",
            "create": "Créer",
            "delete": "Supprimer",
            "confirm": "Valider",
            "show": "Voir",
            "edit": "Editer",
            "update": "Mettre à jour",
            "view": "Voir",
            "preview": "Prévisualiser",
            "back": "Retour",
            "send": "Envoyer",
            "login-as": "Se loguer en tant que {name}",
            "apply": "Appliquer",
            "add": "Ajouter",
            "remove": "Enlever",
            "change": "Changer",
            "reopen": "Ouvrir à nouveau",
            "save_and_publish": "Enregistrer et publier",
            "save_as_draft": "Enregistrer en tant que brouillon",
            "export_satisfaction_stats": "Exporter les statistiques des questionnaires de satisfaction",
            "users": {
                "create": "Créer un utilisateur"
            },
            "roles": {
                "create": "Créer un rôle"
            },
            "metas": {
                "create": "Créer une meta"
            },
            "form_settings": {
                "create": "Créer un paramètre"
            },
            "redirections": {
                "create": "Créer une redirection",
                "import": "Importer le CSV"
            },
            "campaigns": {
                "create": "Créer une campagne",
                "save_and_publish": "Enregistrer et publier",
                "save_as_draft": "Enregistrer en tant que brouillon",
                "finish_campaign": "Clôturer la campagne"
            },
            "courses": {
                "create": "Créer une formation",
                "save_and_publish": "Enregistrer et publier",
                "save_as_draft": "Enregistrer en tant que brouillon"
            },
            "registration": {
                "create": "Créer une inscription",
                "save_and_publish": "Enregistrer et publier",
                "save_as_draft": "Enregistrer en tant que brouillon",
                "relations": {
                    "registrations": {
                        "create": "Créer un candidat",
                        "add": "Ajouter des candidats"
                    },
                    "work_experience": {
                        "work_experience_paid": {
                            "add_work_experience_paid": "Ajouter une expérience professionelle rémunérées",
                            "remove_work_experience_paid": "Retirer cette expérience professionelle"
                        },
                        "work_experience_music": {
                            "add_work_experience_music": "Ajouter une expérience professionelle dans le monde de la musique",
                            "remove_work_experience_music": "Retirer cette expérience professionelle"
                        }
                    },
                    "education": {
                        "add_study": "Ajouter une formation",
                        "remove_study": "Retirer cette formation"
                    }
                }
            }
        },
        "exceptions": {
            "general": "Erreur serveur",
            "unauthorized": "Action non autorisée",
            "disconnected": "Votre session a expirée",
            "backend": {
                "users": {
                    "create": "Erreur lors de la création de l'utilisateur",
                    "update": "Erreur lors de la mise à jour de l'utilisateur",
                    "delete": "Erreur lors de la suppression de l'utilisateur",
                    "first_user_cannot_be_edited": "Vous ne pouvez pas éditer l'utilisateur super admin",
                    "first_user_cannot_be_disabled": "L'utilisateur super admin ne peut pas être désactivé",
                    "first_user_cannot_be_destroyed": "L'utilisateur super admin ne peut pas être supprimé",
                    "first_user_cannot_be_impersonated": "L'utilisateur super admin ne peut pas être usurpé",
                    "cannot_set_superior_roles": "Vous ne pouvez pas attribuer de rôle supérieur au vôtre"
                },
                "roles": {
                    "create": "Erreur lors de la création du rôle",
                    "update": "Erreur lors de la mise à jour du rôle",
                    "delete": "Erreur lors de la suppression du rôle"
                },
                "metas": {
                    "create": "Erreur lors de la création de la meta",
                    "update": "Erreur lors de la mise à jour de la meta",
                    "delete": "Erreur lors de la suppression de la meta",
                    "already_exist": "Il existe déjà une meta pour cette route"
                },
                "form_submissions": {
                    "create": "Erreur lors de la création de la soumission",
                    "delete": "Erreur lors de la suppression de la soumission"
                },
                "form_settings": {
                    "create": "Erreur lors de la création du paramètre de formulaire",
                    "update": "Erreur lors de la mise à jour du paramètre de formulaire",
                    "delete": "Erreur lors de la suppression du paramètre de formulaire",
                    "already_exist": "Il existe déjà un paramétrage pour ce formulaire"
                },
                "redirections": {
                    "create": "Erreur lors de la création de la redirection",
                    "update": "Erreur lors de la mise à jour de la redirection",
                    "delete": "Erreur lors de la suppression de la redirection",
                    "already_exist": "Il existe déjà une redirection pour ce chemin"
                },
                "posts": {
                    "create": "Erreur lors de la création de l'article",
                    "update": "Erreur lors de la mise à jour de l'article",
                    "save": "Erreur lors de l'enregistrement de l'article",
                    "delete": "Erreur lors de la suppression de l'article"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "Cet email est déjà utilisé par un compte existant.",
                    "password_mismatch": "L'ancien mot de passe est incorrect.",
                    "delete_account": "Erreur lors de la suppression de votre compte.",
                    "updating_disabled": "La modification de compte est désactivée."
                },
                "auth": {
                    "registration_disabled": "L'enregistrement d'utilisateurs est désactivé."
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "Formulaire de contact"
            }
        },
        "labels": {
            "language": "Langue",
            "actions": "Actions",
            "general": "Général",
            "no_results": "Aucun résultat trouvé",
            "search": "Rechercher",
            "search_results": "Résultats",
            "search_no_results": "Aucun résultat pour votre recherche",
            "validate": "Valider",
            "choose_file": "Sélectionner un fichier",
            "browse": "Parcourir",
            "download": "Télécharger",
            "no_file_chosen": "Aucun fichier sélectionné",
            "are_you_sure": "Etes-vous sûr ?",
            "unsaved_change_will_be_lost": "Les informations non sauvegardées seront perdues",
            "delete_image": "Supprimer l'image",
            "yes": "Oui",
            "no": "Non",
            "add_new": "Ajouter",
            "remove": "Enlever",
            "export": "Exporter",
            "more_info": "Plus d'info",
            "last_access_at": "Dernier accès le",
            "author": "Auteur",
            "author_id": "ID Auteur",
            "published_at": "Publié le",
            "created_at": "Créé le",
            "updated_at": "Modifié le",
            "deleted_at": "Supprimé le",
            "no_value": "Aucune valeur",
            "upload_image": "Transférer une image",
            "delete_file": "Supprimer le fichier",
            "anonymous": "Anonyme",
            "all_rights_reserved": "Tous droits réservés.",
            "with": "avec",
            "by": "par",
            "order": "Ré-ordonner",
            "share": "Partager",
            "loading": "Chargement",
            "password": "Mot de passe",
            "datatables": {
                "no_results": "Aucun résultat trouvé",
                "no_matched_results": "Aucun résultat correspondant à votre recherche",
                "show_per_page": "Afficher",
                "entries_per_page": "éléments par page",
                "search": "Rechercher",
                "infos": "Affichage de l'élément {offset_start} à {offset_end} sur {total} éléments"
            },
            "modal": {
                "relation": {
                    "registrations": {
                        "name": "Ajouter des candidats"
                    },
                    "stars": {
                        "name": "Add People"
                    },
                    "videos": {
                        "title": "Add Videos"
                    }
                }
            },
            "morphs": {
                "post": "Article, identifiant {id}",
                "user": "Utilisateur, identifiant {id}"
            },
            "auth": {
                "disabled": "Votre compte a été désactivé."
            },
            "http": {
                "403": {
                    "title": "Accès non autorisé",
                    "description": "Désolé, mais vous n'avez pas les permissions pour accéder à cette page."
                },
                "404": {
                    "title": "Page introuvable",
                    "description": "Désolé, mais la page à laquelle vous tentez d'accéder n'existe pas."
                },
                "500": {
                    "title": "Erreur serveur",
                    "description": "Désolé, mais le serveur a rencontré une erreur non prévue. Nous la fixerons dès que possible."
                }
            },
            "localization": {
                "en": "Anglais",
                "fr": "Français"
            },
            "placeholders": {
                "route": "Sélectionner une route interne valide",
                "tags": "Sélectionner ou créer une étiquette",
                "genres": "Sélectionner ou créer un genre",
                "positions": "Sélectionner ou créer un poste"
            },
            "cookieconsent": {
                "message": "En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies afin de réaliser des statistiques de visites.",
                "dismiss": "J'ai compris !"
            },
            "descriptions": {
                "allowed_image_types": "Extensions autorisés: png gif jpg jpeg.",
                "allowed_pdf_type": "Document au format PDF uniquement."
            },
            "user": {
                "dashboard": "Tableau de bord",
                "remember": "Se souvenir",
                "login": "Connexion",
                "logout": "Déconnexion",
                "password_forgot": "Mot de passe oublié ?",
                "send_password_link": "Envoyer un lien de réinitialisation du mot de passe",
                "password_reset": "Réinitialisation du mot de passe",
                "set_password": "Définition du mot de passe",
                "password_wrong": "Mot de passe incorrect",
                "register": "S'inscrire",
                "subscribe": "S'abonner",
                "space": "Mon espace",
                "settings": "Paramètres",
                "account": "Mon compte",
                "profile": "Mon profil",
                "avatar": "Avatar",
                "online": "En ligne",
                "edit_profile": "Mettre à jour mon profil",
                "change_password": "Changer mon mot de passe",
                "delete": "Supprimer mon compte",
                "administration": "Administration",
                "member_since": "Membre depuis le {date}",
                "profile_updated": "Profil modifié avec succès.",
                "password_updated": "Mot de passe modifié avec succès.",
                "super_admin": "Super admin",
                "connect_with": "Se connecter avec Facebook :",
                "account_delete": "Cette action supprimera définitivement votre compte de ce site ainsi que toutes vos données associées.",
                "account_deleted": "Compte supprimé avec succès",
                "membership_and_billing": "Abonnement & Facturation",
                "membership_account": "Informations du compte",
                "membership_invoices": "Factures d'abonnement",
                "membership_payperview_invoices": "Factures des vidéos à la carte",
                "cancel_membership": "Annuler l'abonnement",
                "membership_ends_at": "Votre abonnement se terminera le ",
                "membership_resume": "Ré-activer l'abonnement",
                "card_update": "Mettre à jour",
                "next_payment": "Prochain paiement",
                "my_card": "Ma carte",
                "change_plan": "Changer de forfait",
                "titles": {
                    "space": "Mon espace",
                    "account": "Mon compte"
                }
            },
            "alerts": {
                "login_as": "Vous êtes actuellement connecté en tant que <strong>{name}</strong>, vous pouvez à tout moment vous reconnecter en tant que <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "Nouveaux articles",
                    "pending_posts": "Articles en attente de publication",
                    "published_posts": "Articles publiés",
                    "active_users": "Utilisateurs actifs",
                    "form_submissions": "Soumissions",
                    "last_posts": "Dernier articles",
                    "last_published_posts": "Dernières publications",
                    "last_pending_posts": "Derniers articles en attente de publication",
                    "last_new_posts": "Derniers nouveaux articles",
                    "all_posts": "Voir tous les articles"
                },
                "new_menu": {
                    "post": "Nouvel article",
                    "form_setting": "Nouveau paramétrage de formulaire",
                    "user": "Nouvel utilisateur",
                    "role": "Nouveau rôle",
                    "meta": "Nouvelle meta",
                    "redirection": "Nouvelle redirection"
                },
                "sidebar": {
                    "vod": "Gestion VOD",
                    "content": "Gestion de contenu",
                    "forms": "Gestion des formulaires",
                    "access": "Gestion des accès",
                    "seo": "Paramétrages SEO",
                    "logs": "Accès aux logs"
                },
                "titles": {
                    "dashboard": "Tableau de bord"
                },
                "users": {
                    "titles": {
                        "main": "Utilisateurs",
                        "index": "Liste des utilisateurs",
                        "create": "Créer un utilisateur",
                        "edit": "Editer un utilisateur"
                    },
                    "actions": {
                        "destroy": "Supprimer les utilisateurs sélectionnés",
                        "enable": "Activer les utilisateurs sélectionnés",
                        "disable": "Désactiver les utilisateurs sélectionnés"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Rôles",
                        "index": "Liste des rôles",
                        "create": "Créer un rôle",
                        "edit": "Editer un rôle"
                    }
                },
                "logs": {
                    "titles": {
                        "main": "Logs",
                        "index": "Liste des logs",
                        "show": "Voir un fichier de log",
                        "edit": "Voir un fichier de log"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "Metas",
                        "index": "Liste des metas",
                        "create": "Créer une meta",
                        "edit": "Editer une meta"
                    },
                    "actions": {
                        "destroy": "Supprimer les metas sélectionnés"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Soumissions",
                        "index": "Liste des soumissions",
                        "show": "Détail de la soumission"
                    },
                    "actions": {
                        "destroy": "Supprimer les soumissions sélectionnées"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "Formulaires",
                        "index": "Liste des paramètres de formulaire",
                        "create": "Création d'un paramètre de formulaire",
                        "edit": "Edition d'un paramètre de formulaire"
                    },
                    "descriptions": {
                        "recipients": "Exemple: 'webmaster@example.com' or 'sales@example.com,support@example.com' . Pour déclarer des destinataires multiples, séparer chaque adresse par une virgule.",
                        "message": "Le message à afficher après la soumission de ce formulaire. Laissez vide pour n'afficher aucun message."
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "Redirections",
                        "index": "Liste des redirections",
                        "create": "Création d'une redirection",
                        "edit": "Modification d'une redirection"
                    },
                    "actions": {
                        "destroy": "Supprimer les redirections sélectionnées",
                        "enable": "Activer les redirections sélectionnées",
                        "disable": "Désactiver les redirections sélectionnées"
                    },
                    "types": {
                        "permanent": "Redirection permanente (301)",
                        "temporary": "Redirection temporaire (302)"
                    },
                    "import": {
                        "title": "Import de fichier CSV",
                        "label": "Sélectionner un fichier CSV à importer",
                        "description": "Le fichier doit avoir 2 colonnes avec en-têtes de colonne \"source\" et \"target\", la redirection sera du type permanent par défaut"
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "Brouillon",
                        "pending": "En attente",
                        "published": "Publié"
                    },
                    "titles": {
                        "main": "Articles",
                        "index": "Liste des articles",
                        "create": "Créer un article",
                        "edit": "Editer un article",
                        "publication": "Options de publication"
                    },
                    "descriptions": {
                        "meta_title": "Si vide, le titre par défaut sera celui de l'article.",
                        "meta_description": "Si vide, la description par défaut sera le résumé de l'article."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Supprimer les articles sélectionnés",
                        "publish": "Publier les articles sélectionnés",
                        "pin": "Epingler les articles sélectionnés",
                        "promote": "Mettre en avant les articles sélectionnés"
                    }
                },
                "courses": {
                    "titles": {
                        "main": "Formations",
                        "index": "Liste des formations",
                        "create": "Créer une formation",
                        "edit": "Editer une formation",
                        "publication": "Options de publication"
                    },
                    "actions": {
                        "destroy": "Supprimer les formations sélectionnées",
                        "remove": "Enlever les formations sélectionnées"
                    }
                },
                "campaigns": {
                    "titles": {
                        "main": "Campagnes",
                        "index": "Liste des campagnes",
                        "create": "Créer une campagne",
                        "edit": "Editer une campagne",
                        "publication": "Options de publication"
                    },
                    "actions": {
                        "destroy": "Supprimer les campagnes sélectionnées",
                        "remove": "Enlever les campagnes sélectionnées"
                    },
                    "statuses": {
                        "draft": "Brouillon",
                        "pending": "Campagne en attente de validation",
                        "published": "Campagne en cours",
                        "finished": "Campagne terminée",
                        "scheduled": "Campagne programmée"
                    },
                    "campaign_users": "Candidats"
                },
                "registrations": {
                    "statuses": {
                        "pending": "En attente",
                        "invited": "Invitation envoyée",
                        "started": "En cours",
                        "finished": "Terminée"
                    },
                    "actions": {
                        "invite": "Inviter les candidats sélectionnées (email d'invitation avec accès)",
                        "delete": "Supprimer les candidats sélectionnés",
                        "notify": "Relancer les candidats sélectionnés"
                    },
                    "titles": {
                        "main": "Inscriptions",
                        "index": "Liste des inscriptions",
                        "create": "Créer une inscription",
                        "add": "Ajouter une inscription",
                        "edit": "Editer une inscription",
                        "publication": "Options de publication",
                        "personal_data": {
                            "title": "État civil et coordonnées"
                        },
                        "professional_status": {
                            "title": "Votre statut professionnel"
                        },
                        "education": {
                            "title": "Formations",
                            "studies": "Diplômes et/ou niveau d'études et formations qualifiantes"
                        },
                        "work_experience": {
                            "title": "Expérience professionelle",
                            "work_experience_paid": {
                                "title": "Expériences professionnelles rémunérées (monde de la musique et hors monde de la musique)",
                                "subtitle": "Expérience Professionnelle"
                            },
                            "work_experience_music": {
                                "title": "Expériences dans le monde de la musique (rémunérées ou non)",
                                "subtitle": "Expérience Professionnelle"
                            },
                            "work_experience_charge_prod": {
                                "title": "Quelles connaissances estimez-vous avoir par rapport à ces différents secteurs de la musique"
                            },
                            "work_experience_techplat": {
                                "title": "Quelles connaissances estimez-vous avoir par rapport à ces différents secteurs des musiques actuelles",
                                "subtitle_1": "Environnement scène",
                                "subtitle_2": "Environnement professionnel"
                            },
                            "work_experience_regprod": {
                                "title": "Quelles connaissances estimez-vous avoir par rapport à ces différents secteurs des musiques actuelles"
                            },
                            "work_experience_booker": {
                                "title": "Quelles connaissances estimez-vous avoir par rapport à ces différents axes du métier ?"
                            }
                        },
                        "professional_project": {
                            "title": "Projet Professionnel"
                        },
                        "documents": {
                            "title": "Documents annexes"
                        },
                        "payment": {
                            "title": "Payer les frais d'inscription",
                            "description": "Veuillez vous acquitter du paiement des frais d'inscription pour un montant de 15€"
                        },
                        "extra_form": {
                            "title": "Afin d'améliorer la qualité de nos services merci de répondre au questionnaire ci-dessous",
                            "description": "Comment avez-vous connu Les Formations d’Issoudun ? Pour la (les) réponse(s), merci de préciser."
                        }
                    }
                }
            },
            "frontend": {
                "titles": {
                    "home": "Accueil",
                    "about": "Qui sommes-nous ?",
                    "contact": "Contact",
                    "blog": "Blog",
                    "message_sent": "Message envoyé",
                    "legal_mentions": "Mentions légales",
                    "terms_of_use": "Conditions d'utilisation",
                    "administration": "Administration",
                    "discover": "Découvrir",
                    "watch": "Lecture"
                },
                "submissions": {
                    "message_sent": "<p>Votre demande de contact a bien été envoyée</p>"
                },
                "placeholders": {
                    "locale": "Sélectionnez votre langue",
                    "timezone": "Sélectionnez votre fuseau horaire"
                },
                "blog": {
                    "published_at": "Publié le {date}",
                    "published_at_with_owner_linkable": "Publié le {date} par <a href=\"{link}\">{name}</a>",
                    "tags": "Tags"
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "Utilisateur ID {user} créé",
                    "updated": "Utilisateur ID {user} mis à jour",
                    "deleted": "Utilisateur ID {user} supprimé"
                },
                "form_submissions": {
                    "created": "Soumission de formulaire ID {form_submission} créée"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Bonjour !",
                "regards": "Cordialement",
                "trouble": "Si vous rencontrer un problème en cliquant sur le bouton {action}, copier et coller l'URL suivante dans votre navigateur :",
                "all_rights_reserved": "Tous droits réservés."
            },
            "password_reset": {
                "subject": "Réinitialisation de mon mot de passe",
                "intro": "Vous recevez cet email car vous avez effectué une demande de réinitialisation de mot de passe.",
                "action": "Réinitialiser le mot de passe",
                "outro": "Si vous n'avez pas fait cette demande de réinitialisation, aucune action n'est requise."
            },
            "contact": {
                "subject": "Nouveau message de contact",
                "new_contact": "Vous avez reçu un nouveau message de contact. Détail de la soumission :"
            },
            "alert": {
                "subject": "[{app_name}] Exception error",
                "message": "Une exception serveur non prévue a été levée dont le message est : {message}.",
                "trace": "Trace complète :"
            },
            "registrations": {
                "subject": "Formulaire d'inscription LFI"
            }
        },
        "pagination": {
            "previous": "&laquo; Précédent",
            "next": "Suivant &raquo;"
        },
        "passwords": {
            "password": "Les mots de passe doivent contenir au moins six caractères et doivent être identiques.",
            "reset": "Votre mot de passe a été réinitialisé !",
            "sent": "Nous vous avons envoyé par courriel le lien de réinitialisation du mot de passe !",
            "token": "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
            "user": "Aucun utilisateur n'a été trouvé avec cette adresse e-mail."
        },
        "permissions": {
            "categories": {
                "blog": "Blog",
                "vod": "VOD",
                "form": "Formulaires",
                "access": "Accès",
                "seo": "SEO",
                "registrations": "Inscriptions"
            },
            "access": {
                "backend": {
                    "display_name": "Accès au backoffice",
                    "description": "Permet l'accès aux pages du backoffice."
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "Voir les paramètres de formulaires",
                    "description": "Peut voir des paramètres de formulaires."
                },
                "form_submissions": {
                    "display_name": "Voir les soumissions de formulaire",
                    "description": "Peut voir des soumissions de formulaire."
                },
                "users": {
                    "display_name": "Voir les utilisateurs",
                    "description": "Peut voir des utilisateurs."
                },
                "roles": {
                    "display_name": "Voir les rôles",
                    "description": "Peut voir des rôles."
                },
                "logs": {
                    "display_name": "Voir les logs",
                    "description": "Peut voir des logs."
                },
                "courses": {
                    "display_name": "Voir les cours",
                    "description": "Peut voir des cours."
                },
                "campaigns": {
                    "display_name": "Voir les campagnes",
                    "description": "Peut voir des campagnes."
                },
                "registrations": {
                    "display_name": "Voir les inscriptions",
                    "description": "Peut voir des inscriptions."
                },
                "metas": {
                    "display_name": "Voir les metas",
                    "description": "Peut voir des metas."
                },
                "redirections": {
                    "display_name": "Voir les redirections",
                    "description": "Peut voir des redirections."
                },
                "posts": {
                    "display_name": "Voir tous les articles",
                    "description": "Peut voir l'ensemble des articles."
                },
                "movies": {
                    "display_name": "View all movies",
                    "description": "Can view all movies."
                },
                "shows": {
                    "display_name": "View all shows",
                    "description": "Can view all shows."
                },
                "seasons": {
                    "display_name": "View all seasons",
                    "description": "Can view all seasons."
                },
                "videos": {
                    "display_name": "View all videos",
                    "description": "Can view all videos."
                },
                "people": {
                    "display_name": "View all people",
                    "description": "Can view all people."
                },
                "own": {
                    "posts": {
                        "display_name": "Voir ses propres articles",
                        "description": "Peut voir ses propres articles."
                    },
                    "courses": {
                        "display_name": "Voir ses propres cours",
                        "description": "Peut voir ses propres cours."
                    },
                    "campaigns": {
                        "display_name": "Voir ses propres campagnes",
                        "description": "Peut voir ses propres campagnes."
                    },
                    "registrations": {
                        "display_name": "Voir ses propres inscriptions",
                        "description": "Peut voir ses propres inscriptions."
                    },
                    "movies": {
                        "display_name": "View own movies",
                        "description": "Can view own movies."
                    },
                    "shows": {
                        "display_name": "View own shows",
                        "description": "Can view own shows."
                    },
                    "seasons": {
                        "display_name": "View own seasons",
                        "description": "Can view own seasons."
                    },
                    "videos": {
                        "display_name": "View own videos",
                        "description": "Can view own videos."
                    },
                    "people": {
                        "display_name": "View own people",
                        "description": "Can view own people."
                    }
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "Créer les paramètres de formulaires",
                    "description": "Peut créer des paramètres de formulaires."
                },
                "users": {
                    "display_name": "Créer les utilisateurs",
                    "description": "Peut créer des utilisateurs."
                },
                "roles": {
                    "display_name": "Créer les rôles",
                    "description": "Peut créer des rôles."
                },
                "courses": {
                    "display_name": "Créer les cours",
                    "description": "Peut créer des cours."
                },
                "campaigns": {
                    "display_name": "Créer les campagnes",
                    "description": "Peut créer des campagnes."
                },
                "registrations": {
                    "display_name": "Créer les inscriptions",
                    "description": "Peut créer des inscriptions."
                },
                "metas": {
                    "display_name": "Créer les metas",
                    "description": "Peut créer des metas."
                },
                "redirections": {
                    "display_name": "Créer les redirections",
                    "description": "Peut créer des redirections."
                },
                "posts": {
                    "display_name": "Créer les articles",
                    "description": "Peut créer des articles."
                },
                "movies": {
                    "display_name": "Create movies",
                    "description": "Can create all movies."
                },
                "shows": {
                    "display_name": "Create shows",
                    "description": "Can create all shows."
                },
                "videos": {
                    "display_name": "Create videos",
                    "description": "Can create all videos."
                },
                "people": {
                    "display_name": "Create people",
                    "description": "Can create all people."
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "Modifier les paramètres de formulaires",
                    "description": "Peut modifier des paramètres de formulaires."
                },
                "users": {
                    "display_name": "Modifier les utilisateurs",
                    "description": "Peut modifier des utilisateurs."
                },
                "roles": {
                    "display_name": "Modifier les rôles",
                    "description": "Peut modifier des rôles."
                },
                "courses": {
                    "display_name": "Modifier les cours",
                    "description": "Peut modifier des cours."
                },
                "campaigns": {
                    "display_name": "Modifier les campagnes",
                    "description": "Peut modifier des campagnes."
                },
                "registrations": {
                    "display_name": "Modifier les inscriptions",
                    "description": "Peut modifier des inscriptions."
                },
                "metas": {
                    "display_name": "Modifier les metas",
                    "description": "Peut modifier des metas."
                },
                "redirections": {
                    "display_name": "Modifier les redirections",
                    "description": "Peut modifier des redirections."
                },
                "posts": {
                    "display_name": "Modifier tous les articles",
                    "description": "Peut modifier l'ensemble des articles."
                },
                "movies": {
                    "display_name": "Edit all movies",
                    "description": "Can edit all movies."
                },
                "shows": {
                    "display_name": "Edit all shows",
                    "description": "Can edit all shows."
                },
                "videos": {
                    "display_name": "Edit all videos",
                    "description": "Can edit all videos."
                },
                "people": {
                    "display_name": "Edit all people",
                    "description": "Can edit all people."
                },
                "own": {
                    "courses": {
                        "display_name": "Modifier ses propres cours",
                        "description": "Peut modifier ses propres cours."
                    },
                    "campaigns": {
                        "display_name": "Modifier ses propres campagnes",
                        "description": "Peut modifier ses propres campagnes."
                    },
                    "registrations": {
                        "display_name": "Modifier ses propres inscriptions",
                        "description": "Peut modifier ses propres inscriptions."
                    },
                    "posts": {
                        "display_name": "Modifier ses propres articles",
                        "description": "Peut modifier ses propres articles."
                    },
                    "movies": {
                        "display_name": "Edit own movies",
                        "description": "Can edit own movies."
                    },
                    "shows": {
                        "display_name": "Edit own shows",
                        "description": "Can edit own shows."
                    },
                    "videos": {
                        "display_name": "Edit own videos",
                        "description": "Can edit own videos."
                    },
                    "people": {
                        "display_name": "Edit own people",
                        "description": "Can edit own people."
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "Supprimer les paramètres de formulaires",
                    "description": "Peut supprimer des paramètres de formulaires."
                },
                "form_submissions": {
                    "display_name": "Supprimer les soumissions de formulaire",
                    "description": "Peut supprimer des soumissions de formulaire."
                },
                "users": {
                    "display_name": "Supprimer les utilisateurs",
                    "description": "Peut supprimer des utilisateurs."
                },
                "roles": {
                    "display_name": "Supprimer les rôles",
                    "description": "Peut supprimer des rôles."
                },
                "courses": {
                    "display_name": "Supprimer les cours",
                    "description": "Peut supprimer des cours."
                },
                "campaigns": {
                    "display_name": "Supprimer les campagnes",
                    "description": "Peut supprimer des campagnes."
                },
                "registrations": {
                    "display_name": "Supprimer les inscriptions",
                    "description": "Peut supprimer des inscriptions."
                },
                "metas": {
                    "display_name": "Supprimer les metas",
                    "description": "Peut supprimer des metas."
                },
                "redirections": {
                    "display_name": "Supprimer les redirections",
                    "description": "Peut supprimer des redirections."
                },
                "posts": {
                    "display_name": "Supprimer tous les articles",
                    "description": "Peut supprimer l'ensemble des articles."
                },
                "movies": {
                    "display_name": "Delete all movies",
                    "description": "Can delete all movies."
                },
                "shows": {
                    "display_name": "Delete all movies",
                    "description": "Can delete all movies."
                },
                "seasons": {
                    "display_name": "Delete all seasons",
                    "description": "Can delete all seasons."
                },
                "videos": {
                    "display_name": "Delete all videos",
                    "description": "Can delete all videos."
                },
                "people": {
                    "display_name": "Delete all people",
                    "description": "Can delete all people."
                },
                "own": {
                    "courses": {
                        "display_name": "Supprimer ses propres cours",
                        "description": "Peut supprimer ses propres cours."
                    },
                    "campaigns": {
                        "display_name": "Supprimer ses propres campagnes",
                        "description": "Peut supprimer ses propres campagnes."
                    },
                    "registrations": {
                        "display_name": "Supprimer ses propres inscriptions",
                        "description": "Peut supprimer ses propres inscriptions."
                    },
                    "posts": {
                        "display_name": "Supprimer ses propres articles",
                        "description": "Peut supprimer ses propres articles."
                    },
                    "movies": {
                        "display_name": "Delete own movies",
                        "description": "Can delete own movies."
                    },
                    "shows": {
                        "display_name": "Delete own shows",
                        "description": "Can delete own shows."
                    },
                    "videos": {
                        "display_name": "Delete own videos",
                        "description": "Can delete own videos."
                    },
                    "people": {
                        "display_name": "Delete own people",
                        "description": "Can delete own people."
                    }
                }
            },
            "publish": {
                "courses": {
                    "display_name": "Publier les cours",
                    "description": "Possibilité de gérer la publication des cours."
                },
                "campaigns": {
                    "display_name": "Publier les campagnes",
                    "description": "Possibilité de gérer la publication des campagnes."
                },
                "registrations": {
                    "display_name": "Publier les inscriptions",
                    "description": "Possibilité de gérer la publication des inscriptions."
                },
                "posts": {
                    "display_name": "Publier les articles",
                    "description": "Possibilité de gérer la publication des articles."
                },
                "movies": {
                    "display_name": "Publish movies",
                    "description": "Can manage movies publication."
                },
                "shows": {
                    "display_name": "Publish shows",
                    "description": "Can manage shows publication."
                }
            },
            "impersonate": {
                "display_name": "Usurpation d'utilisateur",
                "description": "Permet de prendre l'identité d'un autre utilisateur. Utile pour les tests."
            }
        },
        "routes": {
            "home": "/",
            "about": "qui-sommes-nous",
            "contact": "demande-de-contact",
            "contact-sent": "message-envoyee",
            "legal-mentions": "mentions-legales",
            "terms-of-use": "cgu",
            "redactors": "blog/redacteurs/{user}",
            "seo": {
                "home": {
                    "title": "Accueil",
                    "description": "Bienvenue sur Montagne TV VOD ! Montagne TV VOD, c'est votre rendez-vous pour voir des films et séries destinés aux amoureux des grands espaces et sensations fortes."
                },
                "browse": {
                    "movies": {
                        "title": "Parcourir (Films)",
                        "description": "Découvrez notre catalogue de films en VOD. Il y en a pour tous les goûts, nouveautés, films classiques, documentaires, sur Montagne TV VOD"
                    },
                    "shows": {
                        "title": "Parcourir (Séries)",
                        "description": "Retrouvez vos émissions et séries préférées en VOD sur Montagne TV VOD"
                    }
                },
                "search": "Recherche"
            }
        },
        "validation": {
            "accepted": "Le champ {attribute} doit être accepté.",
            "active_url": "Le champ {attribute} n'est pas une URL valide.",
            "after": "Le champ {attribute} doit être une date postérieure au {date}.",
            "after_or_equal": "Le champ {attribute} doit être une date postérieure ou égal au {date}.",
            "alpha": "Le champ {attribute} doit seulement contenir des lettres.",
            "alpha_dash": "Le champ {attribute} doit seulement contenir des lettres, des chiffres et des tirets.",
            "alpha_num": "Le champ {attribute} doit seulement contenir des chiffres et des lettres.",
            "array": "Le champ {attribute} doit être un tableau.",
            "before": "Le champ {attribute} doit être une date antérieure au {date}.",
            "before_or_equal": "Le champ {attribute} doit être une date antérieure ou égal au {date}.",
            "between": {
                "numeric": "La valeur de {attribute} doit être comprise entre {min} et {max}.",
                "file": "La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.",
                "string": "Le texte {attribute} doit contenir entre {min} et {max} caractères.",
                "array": "Le tableau {attribute} doit contenir entre {min} et {max} éléments."
            },
            "boolean": "Le champ {attribute} doit être vrai ou faux.",
            "confirmed": "Le champ de confirmation {attribute} ne correspond pas.",
            "date": "Le champ {attribute} n'est pas une date valide.",
            "date_format": "Le champ {attribute} ne correspond pas au format {format}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "Le champ {attribute} doit contenir {digits} chiffres.",
            "digits_between": "Le champ {attribute} doit contenir entre {min} et {max} chiffres.",
            "dimensions": "La taille de l'image {attribute} n'est pas conforme.",
            "distinct": "Le champ {attribute} a une valeur dupliquée.",
            "email": "Le champ {attribute} doit être une adresse e-mail valide.",
            "exists": "Le champ {attribute} sélectionné est invalide.",
            "file": "Le champ {attribute} doit être un fichier.",
            "filled": "Le champ {attribute} doit avoir une valeur.",
            "image": "Le champ {attribute} doit être une image.",
            "in": "Le champ {attribute} est invalide.",
            "in_array": "Le champ {attribute} n'existe pas dans {other}.",
            "integer": "Le champ {attribute} doit être un entier.",
            "ip": "Le champ {attribute} doit être une adresse IP valide.",
            "ipv4": "Le champ {attribute} doit être une adresse IPv4 valide.",
            "ipv6": "Le champ {attribute} doit être une adresse IPv6 valide.",
            "json": "Le champ {attribute} doit être un document JSON valide.",
            "max": {
                "numeric": "La valeur de {attribute} ne peut être supérieure à {max}.",
                "file": "La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.",
                "string": "Le texte de {attribute} ne peut contenir plus de {max} caractères.",
                "array": "Le tableau {attribute} ne peut contenir plus de {max} éléments."
            },
            "mimes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "mimetypes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "min": {
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {min}.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {min} kilo-octets.",
                "string": "Le texte {attribute} doit contenir au moins {min} caractères.",
                "array": "Le tableau {attribute} doit contenir au moins {min} éléments."
            },
            "not_in": "Le champ {attribute} sélectionné n'est pas valide.",
            "not_regex": "Le format du champ {attribute} n'est pas valide.",
            "numeric": "Le champ {attribute} doit contenir un nombre.",
            "present": "Le champ {attribute} doit être présent.",
            "regex": "Le format du champ {attribute} est invalide.",
            "required": "Le champ {attribute} est obligatoire.",
            "required_if": "Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.",
            "required_unless": "Le champ {attribute} est obligatoire sauf si {other} est {values}.",
            "required_with": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_with_all": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            "required_without_all": "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            "same": "Les champs {attribute} et {other} doivent être identiques.",
            "size": {
                "numeric": "La valeur de {attribute} doit être {size}.",
                "file": "La taille du fichier de {attribute} doit être de {size} kilo-octets.",
                "string": "Le texte de {attribute} doit contenir {size} caractères.",
                "array": "Le tableau {attribute} doit contenir {size} éléments."
            },
            "string": "Le champ {attribute} doit être une chaîne de caractères.",
            "timezone": "Le champ {attribute} doit être un fuseau horaire valide.",
            "unique": "La valeur du champ {attribute} est déjà utilisée.",
            "uploaded": "Le fichier du champ {attribute} n'a pu être téléchargé.",
            "url": "Le format de l'URL de {attribute} n'est pas valide.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Nom",
                "display_name": "Nom affiché",
                "username": "Pseudo",
                "email": "Adresse e-mail",
                "first_name": "Prénom",
                "last_name": "Nom",
                "password": "Mot de passe",
                "password_confirmation": "Confirmation du mot de passe",
                "old_password": "Ancien mot de passe",
                "new_password": "Nouveau mot de passe",
                "new_password_confirmation": "Confirmation du nouveau mot de passe",
                "postal_code": "Code postal",
                "send_registration_email": "Envoyer l'invitation et les informations de connexion par email",
                "city": "Ville",
                "country": "Pays",
                "zipcode": "Code postal",
                "birth_date": "Date de naissance",
                "birth_city": "Ville de naissance",
                "birth_zipcode": "Code postal de naissance",
                "nationality": "Nationalité",
                "social_security_num": "Numéro de sécurité sociale",
                "child_nbr": "Nombre d'enfants",
                "family_status": "Statut familial",
                "address": "Adresse",
                "phone": "Téléphone",
                "mobile": "Portable",
                "age": "Age",
                "sex": "Sexe",
                "gender": "Genre",
                "day": "Jour",
                "month": "Mois",
                "year": "Année",
                "hour": "Heure",
                "minute": "Minute",
                "second": "Seconde",
                "title": "Titre",
                "content": "Contenu",
                "description": "Description",
                "summary": "Résumé",
                "excerpt": "Extrait",
                "date": "Date",
                "time": "Heure",
                "available": "Disponible",
                "size": "Taille",
                "roles": "Rôles",
                "permissions": "Permissions",
                "active": "Actif",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localisation",
                "route": "Route",
                "url": "Alias URL",
                "form_type": "Type de formulaire",
                "form_data": "Données du formulaire",
                "recipients": "Destinataires",
                "source_path": "Chemin origine",
                "target_path": "Chemin cible",
                "redirect_type": "Type de redirection",
                "timezone": "Fuseau horaire",
                "order": "Ordre d'affichage",
                "image": "Image",
                "status": "Statut",
                "pinned": "Epinglé",
                "promoted": "Mis en avant",
                "free_access": "En libre accès",
                "body": "Corps",
                "tags": "Etiquettes",
                "genres": "Genres",
                "current_step": "Étape",
                "positions": "Position",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "course": "Formations",
                "campaign": "Campagne d'inscription",
                "start_date": "Date de début",
                "end_date": "Date de Fin",
                "timestamp": "Date et heure",
                "env": "Environnement",
                "type": "Type",
                "lines": "Lignes",
                "job_status": "Situation professionnelle",
                "job_status_note": "Remarques sur votre situation actuelle",
                "pole_emploi_registration_date": "Date d'inscription pôle emploi",
                "pole_emploi_login": "N° identifiant Pole Emploi",
                "pole_emploi_region": "Région d'inscription",
                "pole_emploi_indem_date_start": "Indemnisé.e par le Pôle Emploi depuis le",
                "pole_emploi_indem_date_end": "Indemnisé.e jusqu'au",
                "pole_emploi_aides": "Bénéficiez-vous de l'ARE ou de l'ASS ?",
                "pole_emploi_pdc": "Pouvez-vous bénéficier d’un Plan de développement des compétences ?",
                "pole_emploi_cpf": "Pouvez-vous bénéficier d’un CPF de Projet de transition professionnelle ?",
                "pole_emploi_rsa": "Bénéficiez-vous du RSA ?",
                "int_spec_droits_date_start": "Intermittent.e.s du spectacle, droits ouvert du",
                "int_spec_droits_date_end": "intermittent.e.s, fin de droits le",
                "job_company_name": "Entreprise Actuelle",
                "job_date_start": "Employé depuis le",
                "job_position": "Votre poste",
                "job_company_size": "Nombre de salarié(e)s",
                "job_company_address": "Adresse de la société",
                "job_company_city": "Ville",
                "job_company_zipcode": "Code postal",
                "job_company_phone": "Téléphone",
                "job_manager_name": "Personne en charge du dossier NOM",
                "job_manager_firstname": "Personne en charge du dossier PRENOM",
                "job_manager_phone": "Personne en charge du dossier TELEPHONE",
                "job_manager_email": "Personne en charge du dossier MAIL",
                "job_moncompteactivite_created": "Avez-vous créé votre espace personnel sur moncompteactivite.gouv.fr ?",
                "job_company_approved": "Avez-vous l’accord de votre employeur ?",
                "job_cpf": "Pouvez-vous bénéficier d’un CPF de Projet de transition professionnel (ex CIF) ?",
                "job_pdc": "Pouvez-vous bénéficier d’un plan de développement des compétences (ex plan de formation) ?",
                "job_fne": "Pouvez-vous bénéficier du FNE-rebond ?",
                "job_company_funding": "Pouvez-vous bénéficier d’un financement par votre employeur ?",
                "formation_other_funding": "À défaut, comment comptez-vous financer cette formation ?",
                "is_intermittent_show": "Êtes vous intermittent du spectacle ?",
                "is_self_employed": "Êtes-vous auto-entrepreneur ?",
                "is_self_employed_date": "Depuis quand êtes-vous auto-entrepreneur ?",
                "hand": "Avez-vous une reconnaissance administrative du handicap ?",
                "hand_type": "Si oui laquelle ?",
                "hand_type_other": "Autre(s)",
                "hand_services": "Avez-vous besoin d'aménagements spéciaux ? (matériel, humain…)",
                "courses": {
                    "english": "Un niveau d'anglais minimum est requis pour cette formation",
                    "office_apps": "Un connaissance minimum des outils word et excel est requise pour cette formation",
                    "description": "Description",
                    "terms": "Condition d'utilisation",
                    "sponsors": "Financeurs",
                    "step1_footer": "Pied de page Étape 1",
                    "step2_footer": "Pied de page Étape 2",
                    "step3_footer": "Pied de page Étape 3",
                    "step4_footer": "Pied de page Étape 4",
                    "step5_footer": "Pied de page Étape 5",
                    "step6_footer": "Pied de page Étape 6",
                    "step7_footer": "Pied de page Étape 7"
                },
                "campaigns": {
                    "reference": "Référence"
                },
                "registration": {
                    "id_photo": "Photo d'identité",
                    "notifications": "Relances",
                    "user_title": "Civilité",
                    "work_experience": {
                        "work_experience_paid": {
                            "date_start": "Date de début de contrat",
                            "date_end": "Date de fin de contrat",
                            "contract": "Type de contrat",
                            "position": "Poste",
                            "company": "Entreprise",
                            "address": "Adresse complète",
                            "note": "Remarques"
                        },
                        "work_experience_music": {
                            "date_start": "Date de début",
                            "date_end": "Date de fin",
                            "organization": "Structure",
                            "position": "Poste",
                            "status": "Statut",
                            "current_step": "Étape",
                            "note": "Remarques"
                        },
                        "work_experience_charge_prod": {
                            "gestion_projet_culturel": "Gestion d'un projet culturel",
                            "gestion_projet_culturel_notes": "Gestion d'un projet culturel remarques",
                            "production_vente_spectacle": "Production et vente d’un spectacle",
                            "production_vente_spectacle_notes": "Production et vente d’un spectacle remarques",
                            "organisation_concert_salle": "Organisation de concerts en salle",
                            "organisation_concert_salle_notes": "Organisation de concerts en salle remarques",
                            "organisation_event_exterieur": "Organisation d’événement en extérieur (festival)",
                            "organisation_event_exterieur_notes": "Organisation d’événement en extérieur (festival) remarques",
                            "edition_oeuvre_musique": "Édition d’une oeuvre de musique (publishing)",
                            "edition_oeuvre_musique_notes": "Édition d’une oeuvre de musique (publishing) remarques",
                            "prod_com_records": "Production et commercialisation de musique enregistrée",
                            "prod_com_records_notes": "Production et commercialisation de musique enregistrée remarques",
                            "management_artistic_project": "Management de projet artistique",
                            "management_artistic_project_notes": "Management de projet artistique remarques"
                        },
                        "work_experience_techplat": {
                            "tech_docs": "Compréhension de la fiche technique",
                            "tech_docs_notes": "Compréhension de la fiche technique remarques",
                            "regie_sound": "Connaissance de la régie sonorisation",
                            "regie_sound_notes": "Connaissance de la régie sonorisation remarques",
                            "regie_stage": "Régie plateau",
                            "regie_stage_notes": "Régie plateau remarques",
                            "electric": "Électricité",
                            "electric_notes": "Électricité remarques",
                            "security": "Sécurité",
                            "security_notes": "Sécurité remarques",
                            "english": "Anglais",
                            "english_notes": "Anglais remarques",
                            "computer": "Informatique",
                            "computer_notes": "Informatique remarques",
                            "music_prod_organization": "Organigramme de la production musicale",
                            "music_prod_organization_notes": "Organigramme de la production musicale remarques",
                            "socio_professional": "Aspects socio professionnels, intermittence",
                            "socio_professional_notes": "Aspects socio professionnels, intermittence remarques"
                        },
                        "work_experience_regprod": {
                            "sound": "Son",
                            "sound_notes": "Son remarques",
                            "light": "Lumière",
                            "light_notes": "Lumière remarques",
                            "stage": "Plateau",
                            "stage_notes": "Plateau remarques",
                            "tech_docs": "Lecture de la fiche technique",
                            "tech_docs_notes": "Lecture de la fiche technique remarques",
                            "planning": "Planning",
                            "planning_notes": "Planning remarques",
                            "budget": "Budget",
                            "budget_notes": "Budget remarques",
                            "contract": "Contrats",
                            "contract_notes": "Contrats remarques",
                            "team_management": "Management d'équipe",
                            "team_management_notes": "Remarques sur le management d'équipe",
                            "security": "Sécurité",
                            "security_notes": "Remarques sur la sécurité"
                        },
                        "work_experience_booker": {
                            "concert_organization": "Organisation d’un concert",
                            "concert_organization_notes": "Remarques sur l'organisation d’un concert",
                            "concert_show": "Production d’un spectacle",
                            "concert_show_notes": "Remarques sur la production d’un spectacle",
                            "booking": "Booking",
                            "booking_notes": "Remarques sur votre experience en Booking",
                            "international_show": "Diffusion de spectacles internationaux",
                            "international_show_notes": "Remarques sur la diffusion de spectacles internationaux",
                            "artists_management": "Management d’artiste",
                            "artists_management_notes": "Remarques sur le management d’artiste",
                            "musical_edition": "Connaissances du secteur de l’édition musicale",
                            "musical_edition_notes": "Remarques sur vos connaissances du secteur de l’édition musicale",
                            "production_edition": "Connaissance du secteur de la production et de l’édition phonographique",
                            "database_skills": "Quelle maîtrise pensez-vous avoir de l’utilisation d’une base de donnée",
                            "collaboration_tools_skills": "Quelle maîtrise pensez-vous avoir de l’utilisation d’outils collaboratifs",
                            "digital_tools_skills": "Quelle maîtrise pensez-vous avoir de l’utilisation d’outils digitaux"
                        }
                    },
                    "education": {
                        "study_title": "Intitulé de la formation",
                        "study_degree": "Diplômes ou titres obtenus",
                        "study_level": "Niveau  <small>(<a target=\"_blank\" href=\"https://www.service-public.fr/particuliers/vosdroits/F199\">Voir la liste des niveaux</a></small>)",
                        "study_duration": "Durée de la formation",
                        "study_graduation_date": "Date de l’obtention du titre",
                        "study_city": "Ville",
                        "study_school_name": "Établissement",
                        "english_level": "Quel niveau estimez-vous avoir en anglais ?  <small>(<a target=\"_blank\" href=\"https://www.service-public.fr/particuliers/vosdroits/F34739\">Voir la liste des niveaux</a></small>)",
                        "english_note": "Quelle démarche pensez vous effectuer pour vous mettre à niveau ?",
                        "word_level": "Quelle maîtrise pensez-vous avoir de Word",
                        "excel_level": "Quelle maîtrise pensez-vous avoir de Excel"
                    },
                    "professional_project": {
                        "project_document": "Pièces jointes à la présentation du projet professionnel (pdf uniquement accepté)",
                        "presentation": "Présentation de votre projet professionnel",
                        "course_benefit": "En quoi la formation va vous aider à atteindre vos objectifs ?",
                        "internship_company": "Avez-vous une idée des entreprises dans lesquelles vous souhaitez effectuer vos stages pratiques ?",
                        "internship_company_name": "Si oui précisez",
                        "internship_same_company": "Avez-vous déjà été candidat à une de nos formations ? Précisez laquelle, les années",
                        "how_did_you_know_us": "Comment avez-vous été orienté vers nous ?"
                    },
                    "documents": {
                        "cv_file": "Un CV (pdf uniquement)",
                        "motivation_letter_file": "Une lettre de motivation d’une page maximum (pdf uniquement)",
                        "diploma_file": "Copies du diplôme du niveau le plus élevé (pdf uniquement)",
                        "references_file": "Recommandations (facultatif, en un seul fichier pdf)",
                        "extras_file": "Attestations diverses (facultatif, en un seul fichier pdf)",
                        "list_documents": "Enumérez les pièces envoyées"
                    },
                    "extra_form": {
                        "search_engine": "Recherche sur un moteur de recherche",
                        "alumni": "Par un (des) ancien(s) stagiaire(s)",
                        "press": "Presse",
                        "event": "Salon",
                        "pro": "Professionnel(s) du spectacle",
                        "website": "Site internet",
                        "pole_emploi": "Pôle emploi",
                        "local_mission": "Mission Locale",
                        "pro_organization": "Par une organisation professionnelle (Syndicats, réseau, etc)",
                        "staff": "Par un membre de léquipe LFI",
                        "others": "Autres",
                        "detail": "Précisez"
                    }
                }
            }
        }
    }
}
