<template>
  <b-card class="mt-4" :class="stripeError ? 'border-danger' : ''">
    <template v-slot:header>
      <a v-b-toggle.modelData.paymentData>
        <h2 class="card-title mb-1 mt-1" slot="header">{{ $t('labels.backend.registrations.titles.payment.title') }}</h2>
      </a>
    </template>
    <b-collapse visible id="documentData" role="tabpanel">
      <b-card-body>
        <div class="" v-if="!modelData.payment">
          <p v-if="!modelData.payment">{{ $t('labels.backend.registrations.titles.payment.description') }}</p>
          <card class="stripe-card"
                :class="{ complete }"
                :stripe="stripeKey"
                :options="stripeOptions"
                @change="cardChanged($event.complete)"
          ></card>
          <b-form-invalid-feedback :state="!stripeError">
            {{ stripeErrorMessage }}
          </b-form-invalid-feedback>
          <button @click="pay" class="pay-with-stripe btn btn-danger finish-button mt-3 float-right" :disabled="pending"><b-spinner v-if="pending" small class="mr-3"></b-spinner>Payer par CB</button>
        </div>
        <div class="" v-if="modelData.payment">
          <h4><i class="fe fe-check"></i> &nbsp; Merci, Votre paiement a bien été validé.</h4>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import axios from 'axios'
import { Card, createToken, handleCardAction, instance as StripeInstance } from 'vue-stripe-elements-plus'

export default {
  name: 'CheckoutForm',
  components: { Card },
  props: {
    modelData: {
      type: Object,
      default: () => {}
    },
    stripeKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      pending: false,
      stripeError: false,
      stripeErrorMessage: '',
      complete: false,
      checkoutData: {
        checkoutComplete: false
      },
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      }
    }
  },
  mounted () {
  },
  methods: {
    validate () {
      if (this.modelData.payment === false) {
        this.stripeError = true
        this.stripeErrorMessage = 'Le paiement est obligatoire. Merci de procéder au règlement pour continuer.'
        return false
      }
      // this.$v.personalData.$touch()
      // var isValid = !this.$v.personalData.$invalid
      // this.$emit('on-validate', this.$v.personalData, isValid)
      return true
    },
    cardChanged (value) {
      this.stripeError = false
      this.stripeErrorMessage = ''
      this.complete = value
    },
    pay () {
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      this.pending = true
      createToken().then(async (data) => {
        if (!data.error) {
          try {
            let checkoutResult = await axios.post(this.$app.route('user.registrations.checkout'), { stripeEmail: this.modelData.registration_user.email, stripeToken: data.token.id, stripeCard: data.token.card.id })
            let result = await StripeInstance.confirmCardPayment(checkoutResult.data.payment_intend_secret)
            if (result.error) {
              this.pending = false
              this.stripeError = true
              this.stripeErrorMessage = result.error.message
              return false
              // Payment failed.
            } else {
              if (result.paymentIntent.status === 'requires_source_action') {
                handleCardAction(checkoutResult.data.payment_intend_secret)
              } else {
                if (result.paymentIntent.status === 'succeeded') {
                  axios.post(this.$app.route('user.registrations.checkout_complete'), { registrationId: this.modelData.id, stripePaymentIntendId: result.paymentIntent.id })
                  this.$emit('context-changed', { 'relation': null, 'property': 'payment', 'value': true })
                  this.pending = false
                  this.modelData.payment = true
                  this.stripeError = false
                  return true
                }
              }
            }
          } catch (e) {
            if (e.response.data.code === 'card_declined') {
              this.stripeError = true
              this.stripeErrorMessage = "Le paiement n'a pas aboutit, veuillez essayer avec un autre moyen de paiement"
              this.pending = false
              return false
            }
            this.pending = false
            return false
          }
        }
      })
    },
    deleteCVFile () {
    // Lalala
    },
    deleteExtraFile () {
    // Lalala
    }
  }
}
</script>
